import defaultValues from './defaultValues'

const before = JSON.parse(defaultValues);

export const before_hover = JSON.parse(defaultValues);
export const before_active = JSON.parse(defaultValues);
export const before_focus = JSON.parse(defaultValues);
export const before_target = JSON.parse(defaultValues);
export const before_disabled = JSON.parse(defaultValues);
export const before_invalid = JSON.parse(defaultValues);

// const all = {before_hover, before_active, before_focus, before_target, before_disabled, before_invalid};

export default before;